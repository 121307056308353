
import { defineComponent } from "vue";
import MdCheckboxMixin from "@/components/md/MdCheckbox/MdCheckboxMixin";
import { uuid } from "@/utils/MdUuid";

export default defineComponent({
  name: "MdRadio",
  mixins: [MdCheckboxMixin],
  props: {
    id: {
      type: String,
      default: () => uuid("radio"),
    },
  },
  methods: {
    toggleOnClick() {
      if (this.disabled || this.isChecked) return;
      this.toggleCheck();
    },
  },
});
